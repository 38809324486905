import { useRouter } from 'next/router';
import { useMount } from 'react-use';
import { AppLoader } from '~/components/AppLoader';
import withAuthRequired from '~/components/withAuthRequired';
import { useAuth } from '~/contexts/auth';

const Index = () => {
  const router = useRouter();
  const { getMainUrl } = useAuth();

  // https://github.com/vercel/next.js/issues/6713
  useMount(() => {
    router.push(getMainUrl());
  });

  return <AppLoader />;
};

export default withAuthRequired(Index);

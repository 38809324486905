import * as PusherPushNotifications from '@pusher/push-notifications-web';
import cookie from 'js-cookie';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import authApi from '~/authApi';
import { BASE_URL_SUFFIX } from '~/clientApi';
import { FEATURE_FLAGS, USER_ROLES } from '~/constants';
import { selectUser } from '~/selectors/user';

import { useFeatureFlag } from '../useFeatureFlag';

export function usePushNotifications() {
  const userRoles = authApi?.user?.roles ?? [];
  const isManager = userRoles.includes(USER_ROLES.talentManager);
  const user = useSelector(selectUser);

  const pushNotificationsFlag = useFeatureFlag(
    FEATURE_FLAGS.foamChromePushNotifications,
  );

  const accessToken = cookie.get('accessToken');
  const instanceId = process.env.NEXT_PUBLIC_PUSHER_INSTANCE_ID;
  const tokenProvider = useMemo(
    () =>
      pushNotificationsFlag === 'enabled' &&
      accessToken &&
      user?.id &&
      isManager
        ? new PusherPushNotifications.TokenProvider({
            url: `${process.env.NEXT_PUBLIC_API_RESOURCE}/${BASE_URL_SUFFIX}/pusher/beams/auth`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        : null,
    [accessToken, isManager, pushNotificationsFlag, user?.id],
  );

  useEffect(() => {
    if (!instanceId || !tokenProvider || !user?.id) {
      return;
    }

    try {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId,
      });

      beamsClient
        .getUserId()
        .then((userId: string) => {
          // Check if the Beams user matches the user currently logged in
          if (userId && userId !== user?.id) {
            // Unregister for notifications
            return beamsClient
              .stop()
              .then(() => {
                beamsClient
                  .start()
                  .then(() => {
                    beamsClient
                      .getRegistrationState()
                      .then(
                        (state: PusherPushNotifications.RegistrationState) => {
                          if (
                            state ===
                            PusherPushNotifications.RegistrationState
                              .PERMISSION_GRANTED_REGISTERED_WITH_BEAMS
                          ) {
                            beamsClient.setUserId(user?.id, tokenProvider);
                          }
                        },
                      )
                      .catch(console.error);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          } else {
            // If there is no userId the client is being instantiated for first time
            beamsClient
              .start()
              .then(() => {
                beamsClient
                  .getRegistrationState()
                  .then((state: PusherPushNotifications.RegistrationState) => {
                    if (
                      state ===
                      PusherPushNotifications.RegistrationState
                        .PERMISSION_GRANTED_REGISTERED_WITH_BEAMS
                    ) {
                      beamsClient.setUserId(user?.id, tokenProvider);
                    }
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          }
        })
        .catch(console.error);
    } catch (error) {
      console.error(error);
    }
  }, [instanceId, tokenProvider, user?.id]);
}

import { intersection } from 'lodash';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useSelector } from 'react-redux';
import authApi from '~/authApi';
import clientApi from '~/clientApi';
import { AppLoader } from '~/components/AppLoader';
import {
  ROOT_URL,
  SOCIAL_PLATFORMS,
  SOCIAL_PLATFORMS_CONNECTION_STATUS,
  USER_PERMISSIONS,
  USER_ROLES,
} from '~/constants';
import { useAuth } from '~/contexts/auth';
import { routes } from '~/core/router';
import { getMainPlatformStatus } from '~/creatorUtils';
import useImpersonateCreator from '~/hooks/useImpersonateCreator';
import { usePushNotifications } from '~/hooks/usePushNotifications';
import {
  selectCreator,
  selectSocialPlatformsConnectionStatus,
} from '~/selectors/creator';

interface IOptions {
  roles?: USER_ROLES[];
  permissions?: USER_PERMISSIONS[];
  platforms?: SOCIAL_PLATFORMS[];
}

const withAuthRequired = (Page: NextPage, options: IOptions = {}) => {
  const {
    permissions: restrictedToPermissions,
    platforms: restrictedToPlatforms,
    roles: receivedRestrictedToRoles,
  } = options;

  const PageWithAuth = (pageProps: React.ComponentProps<typeof Page>) => {
    const creator = useSelector(selectCreator);
    const { getMainUrl, skipReconnect } = useAuth();
    const { clearImpersonatedCreator } = useImpersonateCreator();
    const router = useRouter();
    const [isLoading, setIsLoading] = React.useState(false);
    const platformsStatus = useSelector(selectSocialPlatformsConnectionStatus);

    usePushNotifications();

    const { hasConnected, hasExpired, hasReAuth } =
      getMainPlatformStatus(creator);

    const connectedPlatforms = Object.entries(platformsStatus).reduce(
      (acc: SOCIAL_PLATFORMS[], [platform, status]) => {
        if (
          [
            SOCIAL_PLATFORMS_CONNECTION_STATUS.connected,
            SOCIAL_PLATFORMS_CONNECTION_STATUS.connectionExpired,
          ].includes(status)
        ) {
          acc.push(platform as SOCIAL_PLATFORMS);
        }
        return acc;
      },
      [],
    );

    React.useEffect(() => {
      try {
        const impersonatingId = clientApi.impersonatingId;
        const { roles: currentUserRoles, permissions: userPermissions } =
          authApi.user;

        const isAnonymous = currentUserRoles.includes(USER_ROLES.anonymous);
        const isCreator = currentUserRoles.includes(USER_ROLES.creator);
        const isManagedCreator = currentUserRoles.includes(USER_ROLES.managed);
        const isManager = currentUserRoles.includes(USER_ROLES.talentManager);
        const hasPlatforms = !!connectedPlatforms.filter(
          (platform) => restrictedToPlatforms?.includes(platform),
        ).length;

        // Grant access to managed role that was creator before the connection
        // dashboard release in case the page is restricted to creator only
        // Only override Roles if received has creator role, to avoid access outside of old creator role
        const restrictedToRoles =
          creator?.platformFullAccess &&
          receivedRestrictedToRoles &&
          receivedRestrictedToRoles.includes(USER_ROLES.creator) &&
          !receivedRestrictedToRoles.includes(USER_ROLES.managed)
            ? [...receivedRestrictedToRoles, USER_ROLES.managed]
            : receivedRestrictedToRoles;

        const hasRequiredRoles =
          intersection(restrictedToRoles ?? [], currentUserRoles).length > 0;
        const hasPermissions =
          intersection(restrictedToPermissions ?? [], userPermissions).length >
          0;

        if (
          creator?.id &&
          !creator?.echoWelcomeSeen &&
          !isManager &&
          currentUserRoles.includes(USER_ROLES.echoCreator) &&
          router.pathname !== routes.echoWelcomeScreen
        ) {
          router.push(routes.echoWelcomeScreen);
          setIsLoading(true);
        }

        if (!currentUserRoles || isAnonymous) {
          const url = new URL(routes.loginSSO);
          if (window.location.pathname !== '/') {
            url.searchParams.append(
              'redirectUrl',
              `${window.location.toString()}`,
            );
          }
          window.location.assign(url);
        }

        if (isManagedCreator && !creator?.platformFullAccess) {
          router.push({
            pathname: routes.connectionDashboard,
          });
          setIsLoading(true);
        }

        // check if user matches restricted roles (or impersonating a user who matches it)...
        if (
          restrictedToRoles &&
          !hasRequiredRoles &&
          (!isManager || !impersonatingId) &&
          router.asPath !== ROOT_URL
        ) {
          // ... and redirect if so
          router.push({ pathname: getMainUrl() });
          setIsLoading(true);
        }

        // check if user matches restricted roles and is a manager
        if (hasRequiredRoles && isManager && impersonatingId && creator.id) {
          // ... and clear any impersonated creators if so
          // clearImpersonatedCreator();
        }

        // if it's a restricted page with permissions and user doesn't have them, redirect
        if (restrictedToPermissions && !hasPermissions) {
          window.location.assign(routes.loginSSO);
        }

        // if it's a restricted page with platforms and user doesn't have them, redirect
        if (restrictedToPlatforms && !hasPlatforms) {
          router.push({ pathname: getMainUrl() });
          setIsLoading(true);
        }

        // check if Creator user is connected to primary platform...
        // if a managed creator reaches to this point, it will have
        // platformFullAccess: true, so it must be managed in the same way
        // than a creator
        if (
          creator?.id &&
          (isCreator || isManagedCreator) &&
          router.pathname !== routes.echoWelcomeScreen
        ) {
          let redirectUri;
          if (!hasConnected) {
            redirectUri = routes.signUpConnectSocials;
          }

          const shouldReconnect = hasExpired || hasReAuth;

          if (!skipReconnect && shouldReconnect) {
            redirectUri = routes.reconnectPlatforms;
          }
          if (redirectUri && router.pathname !== redirectUri) {
            router.push(redirectUri);
            setIsLoading(true);
          }
        }

        // TODO: Remove this if when the 409 error was fixed by BE.
        // What we're doing here is to check if the user has a JWT with
        // any creator role but the FOAM API doesn't be able to return the
        // creator, so we trows an error to show the user a 500 page.
        if (
          !creator?.id &&
          (currentUserRoles.includes(USER_ROLES.creator) ||
            currentUserRoles.includes(USER_ROLES.managed) ||
            currentUserRoles.includes(USER_ROLES.echoCreator))
        ) {
          throw new Error('WW-6825');
        }
      } catch (error) {
        if (error.message === 'WW-6825') {
          throw error;
        } else {
          // redirect to login if error (not authenticated or user entity doesn't exist yet)
          window.location.assign(routes.loginSSO);
        }
      }
    }, [
      clearImpersonatedCreator,
      connectedPlatforms,
      creator?.echoWelcomeSeen,
      creator?.id,
      creator?.platformFullAccess,
      getMainUrl,
      hasConnected,
      hasExpired,
      hasReAuth,
      pageProps,
      router,
      skipReconnect,
    ]);

    if (isLoading) {
      return <AppLoader />;
    }
    return <Page {...pageProps} />;
  };

  return PageWithAuth;
};

export default withAuthRequired;
